package ca.derekellis.reroute.db

import app.cash.sqldelight.ColumnAdapter
import com.soywiz.klock.DateTime
import kotlin.String

public data class Metadata(
  public val updated: DateTime,
) {
  public class Adapter(
    public val updatedAdapter: ColumnAdapter<DateTime, String>,
  )
}
