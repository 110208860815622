package ca.derekellis.reroute.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.SqlDriver
import com.soywiz.klock.DateTime

public class MetadataQueries(
  driver: SqlDriver,
  private val MetadataAdapter: Metadata.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun `get`(): Query<DateTime> = Query(57_566_555, arrayOf("Metadata"), driver,
      "Metadata.sq", "get", "SELECT * FROM Metadata") { cursor ->
    MetadataAdapter.updatedAdapter.decode(cursor.getString(0)!!)
  }

  public suspend fun insert(Metadata: Metadata) {
    driver.execute(1_338_929_812, """INSERT INTO Metadata (updated) VALUES (?)""", 1) {
          bindString(0, MetadataAdapter.updatedAdapter.encode(Metadata.updated))
        }.await()
    notifyQueries(1_338_929_812) { emit ->
      emit("Metadata")
    }
  }

  public suspend fun update(updated: DateTime) {
    driver.execute(1_683_876_004, """UPDATE Metadata SET updated = ?""", 1) {
          bindString(0, MetadataAdapter.updatedAdapter.encode(updated))
        }.await()
    notifyQueries(1_683_876_004) { emit ->
      emit("Metadata")
    }
  }

  public suspend fun clear() {
    transaction {
      driver.execute(1_751_756_355, """DELETE FROM RouteVariant""", 0).await()
      driver.execute(1_751_756_356, """DELETE FROM RouteVariantAtStop""", 0).await()
      driver.execute(1_751_756_357, """DELETE FROM Stop""", 0).await()
      driver.execute(1_751_756_358, """DELETE FROM Route""", 0).await()
    }
    notifyQueries(-516_612_334) { emit ->
      emit("Route")
      emit("RouteVariant")
      emit("RouteVariantAtStop")
      emit("Stop")
      emit("StopSearch")
    }
  }
}
