package ca.derekellis.reroute.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Int
import kotlin.String

public class RouteVariantAtStopQueries(
  driver: SqlDriver,
  private val RouteVariantAtStopAdapter: RouteVariantAtStop.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getVariantsByStopCode(code: String, mapper: (
    stopId: String,
    routeVariantId: String,
    index: Int,
  ) -> T): Query<T> = GetVariantsByStopCodeQuery(code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      RouteVariantAtStopAdapter.indexAdapter.decode(cursor.getLong(2)!!)
    )
  }

  public fun getVariantsByStopCode(code: String): Query<RouteVariantAtStop> =
      getVariantsByStopCode(code) { stopId, routeVariantId, index ->
    RouteVariantAtStop(
      stopId,
      routeVariantId,
      index
    )
  }

  private inner class GetVariantsByStopCodeQuery<out T : Any>(
    public val code: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("RouteVariantAtStop", "Stop", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("RouteVariantAtStop", "Stop", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_609_960_813, """
    |SELECT RouteVariantAtStop.* FROM Stop
    |  JOIN RouteVariantAtStop
    |  ON Stop.id = RouteVariantAtStop.stopId
    |  WHERE Stop.code = ?
    |  ORDER BY RouteVariantAtStop.routeVariantId
    """.trimMargin(), mapper, 1) {
      bindString(0, code)
    }

    override fun toString(): String = "RouteVariantAtStop.sq:getVariantsByStopCode"
  }
}
