package ca.derekellis.reroute.db

import app.cash.sqldelight.SuspendingTransacter
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import ca.derekellis.reroute.db.web.newInstance
import ca.derekellis.reroute.db.web.schema
import kotlin.Unit

public interface RerouteDatabase : SuspendingTransacter {
  public val metadataQueries: MetadataQueries

  public val routeQueries: RouteQueries

  public val routeVariantQueries: RouteVariantQueries

  public val routeVariantAtStopQueries: RouteVariantAtStopQueries

  public val stopQueries: StopQueries

  public val stopSearchQueries: StopSearchQueries

  public companion object {
    public val Schema: SqlSchema<QueryResult.AsyncValue<Unit>>
      get() = RerouteDatabase::class.schema

    public operator fun invoke(
      driver: SqlDriver,
      MetadataAdapter: Metadata.Adapter,
      RouteAdapter: Route.Adapter,
      RouteVariantAdapter: RouteVariant.Adapter,
      RouteVariantAtStopAdapter: RouteVariantAtStop.Adapter,
    ): RerouteDatabase = RerouteDatabase::class.newInstance(driver, MetadataAdapter, RouteAdapter,
        RouteVariantAdapter, RouteVariantAtStopAdapter)
  }
}
