package ca.derekellis.reroute

import kotlin.OptIn
import kotlin.String
import kotlin.js.ExperimentalJsExport
import kotlin.js.JsExport

@JsExport
@OptIn(ExperimentalJsExport::class)
public object RerouteConfig {
  public val MAPBOX_ACCESS_KEY: String =
      "pk.eyJ1IjoiZGVsbGlzZCIsImEiOiJja3hwOWxhbTExc3I3MnBtd25jcXVjb2JnIn0.1fP9HzNcbjsfUc680y9eMg"
}
