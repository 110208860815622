package ca.derekellis.reroute.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Double
import kotlin.Int
import kotlin.String
import kotlin.collections.List

public class StopQueries(
  driver: SqlDriver,
  private val RouteAdapter: Route.Adapter,
  private val RouteVariantAdapter: RouteVariant.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getById(id: String, mapper: (
    id: String,
    code: String,
    name: String,
    lat: Double,
    lon: Double,
    parent: String?,
  ) -> T): Query<T> = GetByIdQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getString(5)
    )
  }

  public fun getById(id: String): Query<Stop> = getById(id) { id_, code, name, lat, lon, parent ->
    Stop(
      id_,
      code,
      name,
      lat,
      lon,
      parent
    )
  }

  public fun <T : Any> getByCode(code: String, mapper: (
    id: String,
    code: String,
    name: String,
    lat: Double,
    lon: Double,
    parent: String?,
  ) -> T): Query<T> = GetByCodeQuery(code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getString(5)
    )
  }

  public fun getByCode(code: String): Query<Stop> = getByCode(code) { id, code_, name, lat, lon,
      parent ->
    Stop(
      id,
      code_,
      name,
      lat,
      lon,
      parent
    )
  }

  public fun <T : Any> getAll(mapper: (
    id: String,
    code: String,
    name: String,
    lat: Double,
    lon: Double,
    parent: String?,
  ) -> T): Query<T> = Query(-1_657_271_719, arrayOf("Stop"), driver, "Stop.sq", "getAll",
      "SELECT * FROM Stop") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getString(5)
    )
  }

  public fun getAll(): Query<Stop> = getAll { id, code, name, lat, lon, parent ->
    Stop(
      id,
      code,
      name,
      lat,
      lon,
      parent
    )
  }

  public fun <T : Any> getRoutesByStopCode(code: String, mapper: (
    gtfsId: String,
    identifier: String,
    destinations: List<String>,
    directionId: Int,
  ) -> T): Query<T> = GetRoutesByStopCodeQuery(code) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      RouteAdapter.destinationsAdapter.decode(cursor.getString(2)!!),
      RouteVariantAdapter.directionIdAdapter.decode(cursor.getLong(3)!!)
    )
  }

  public fun getRoutesByStopCode(code: String): Query<GetRoutesByStopCode> =
      getRoutesByStopCode(code) { gtfsId, identifier, destinations, directionId ->
    GetRoutesByStopCode(
      gtfsId,
      identifier,
      destinations,
      directionId
    )
  }

  public suspend fun insert(Stop: Stop) {
    driver.execute(-1_591_696_729,
        """INSERT OR IGNORE INTO Stop (id, code, name, lat, lon, parent) VALUES (?, ?, ?, ?, ?, ?)""",
        6) {
          bindString(0, Stop.id)
          bindString(1, Stop.code)
          bindString(2, Stop.name)
          bindDouble(3, Stop.lat)
          bindDouble(4, Stop.lon)
          bindString(5, Stop.parent)
        }.await()
    notifyQueries(-1_591_696_729) { emit ->
      emit("Stop")
      emit("StopSearch")
    }
  }

  private inner class GetByIdQuery<out T : Any>(
    public val id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("Stop", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("Stop", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(164_225_562, """SELECT * FROM Stop WHERE id = ?""", mapper, 1) {
      bindString(0, id)
    }

    override fun toString(): String = "Stop.sq:getById"
  }

  private inner class GetByCodeQuery<out T : Any>(
    public val code: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("Stop", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("Stop", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_093_189_844, """SELECT * FROM Stop WHERE code = ?""", mapper, 1) {
      bindString(0, code)
    }

    override fun toString(): String = "Stop.sq:getByCode"
  }

  private inner class GetRoutesByStopCodeQuery<out T : Any>(
    public val code: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("Route", "RouteVariant", "RouteVariantAtStop", "Stop", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("Route", "RouteVariant", "RouteVariantAtStop", "Stop", listener =
          listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(1_062_419_384, """
    |SELECT DISTINCT Route.gtfsId, Route.identifier, Route.destinations, RouteVariant.directionId FROM Route
    |  JOIN RouteVariant ON Route.gtfsId = RouteVariant.gtfsId
    |  JOIN RouteVariantAtStop ON RouteVariantAtStop.routeVariantId = RouteVariant.id
    |  WHERE stopId = (
    |    SELECT id FROM Stop WHERE code = ?
    |  )
    |  ORDER BY RouteVariant.id, RouteVariant.weight
    """.trimMargin(), mapper, 1) {
      bindString(0, code)
    }

    override fun toString(): String = "Stop.sq:getRoutesByStopCode"
  }
}
