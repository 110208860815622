package ca.derekellis.reroute.db

import app.cash.sqldelight.ColumnAdapter
import io.github.dellisd.spatialk.geojson.LineString
import kotlin.Int
import kotlin.Long
import kotlin.String

public data class RouteVariant(
  public val id: String,
  public val gtfsId: String,
  public val directionId: Int,
  public val headsign: String,
  public val weight: Int,
  public val shape: LineString,
) {
  public class Adapter(
    public val directionIdAdapter: ColumnAdapter<Int, Long>,
    public val weightAdapter: ColumnAdapter<Int, Long>,
    public val shapeAdapter: ColumnAdapter<LineString, String>,
  )
}
