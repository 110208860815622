package ca.derekellis.reroute.di

import ca.derekellis.reroute.PresenterFactory
import ca.derekellis.reroute.RerouteApplication
import ca.derekellis.reroute.ViewFactory
import ca.derekellis.reroute.`data`.DataSource
import ca.derekellis.reroute.`data`.RerouteClient
import ca.derekellis.reroute.db.DatabaseHelper
import ca.derekellis.reroute.home.HomePresenter
import ca.derekellis.reroute.map.MapInteractionsManager
import ca.derekellis.reroute.map.MapPresenter
import ca.derekellis.reroute.search.SearchPresenter
import ca.derekellis.reroute.stops.StopPresenter
import ca.derekellis.reroute.ui.AppNavigator
import ca.derekellis.reroute.ui.Application
import kotlin.reflect.KClass
import me.tatarka.inject.`internal`.LazyMap
import me.tatarka.inject.`internal`.ScopedComponent

public fun KClass<AppComponent>.create(): AppComponent = InjectAppComponent()

public class InjectAppComponent() : AppComponent(), ScopedComponent {
  override val _scoped: LazyMap = LazyMap()

  private val rerouteClient: RerouteClient
    get() = _scoped.get("ca.derekellis.reroute.`data`.RerouteClient") {
      RerouteClient(
        client = _scoped.get("io.ktor.client.HttpClient") {
          provideHttpClient()
        }
      )
    }

  private val dataSource: DataSource
    get() = _scoped.get("ca.derekellis.reroute.`data`.DataSource") {
      dataSource(
        helper = databaseHelper
      )
    }

  private val databaseHelper: DatabaseHelper
    get() = _scoped.get("ca.derekellis.reroute.db.DatabaseHelper") {
      DatabaseHelper(
        worker = _scoped.get("org.w3c.dom.Worker") {
          provideWorker()
        },
        client = rerouteClient
      )
    }

  private val mapInteractionsManager: MapInteractionsManager
    get() = _scoped.get("ca.derekellis.reroute.map.MapInteractionsManager") {
      MapInteractionsManager()
    }

  override val application: Application
    get() = {
      ca.derekellis.reroute.ui.Application(
        searchScreenWrapper = _scoped.get("ca.derekellis.reroute.ui.SearchScreenWrapper") {
          searchScreenWrapper(
            viewFactory = ViewFactory(),
            presenterFactory = presenterFactory
          )
        }
      )
    }

  override val rerouteApplication: RerouteApplication
    get() = _scoped.get("ca.derekellis.reroute.RerouteApplication") {
      RerouteApplication(
        databaseHelper = databaseHelper
      )
    }

  override val appNavigator: AppNavigator
    get() = _scoped.get("ca.derekellis.reroute.ui.AppNavigator") {
      AppNavigator(
        presenterFactory = presenterFactory,
        viewFactory = ViewFactory()
      )
    }

  override val viewFactory: ViewFactory
    get() = ViewFactory()

  override val presenterFactory: PresenterFactory
    get() = PresenterFactory(
      stopPresenterFactory = StopPresenter.Factory(
        create = { arg0, arg1 ->
          StopPresenter(
            client = rerouteClient,
            dataSource = dataSource,
            mapInteractionsManager = mapInteractionsManager,
            navigator = arg0,
            args = arg1
          )
        }
      ),
      homePresenterFactory = HomePresenter.Factory(
        create = { arg0 ->
          HomePresenter(
            args = arg0
          )
        }
      ),
      mapPresenterFactory = MapPresenter.Factory(
        create = { arg0, arg1 ->
          MapPresenter(
            dataSource = dataSource,
            interactionsManager = mapInteractionsManager,
            withDatabase = databaseHelper,
            client = rerouteClient,
            navigator = arg0,
            args = arg1
          )
        }
      ),
      searchPresenterFactory = SearchPresenter.Factory(
        create = { arg0, arg1 ->
          SearchPresenter(
            dataSource = dataSource,
            navigator = arg0,
            args = arg1
          )
        }
      )
    )
}
