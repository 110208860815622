package ca.derekellis.reroute.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import kotlin.Any
import kotlin.Double
import kotlin.String

public class StopSearchQueries(
  driver: SqlDriver,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> search2(query: String, mapper: (
    id: String,
    code: String,
    name: String,
    lat: Double,
    lon: Double,
    parent: String?,
  ) -> T): Query<T> = Search2Query(query) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getString(5)
    )
  }

  public fun search2(query: String): Query<Stop> = search2(query) { id, code, name, lat, lon,
      parent ->
    Stop(
      id,
      code,
      name,
      lat,
      lon,
      parent
    )
  }

  public fun <T : Any> search(query: String, mapper: (
    id: String,
    code: String,
    name: String,
    lat: Double,
    lon: Double,
    parent: String?,
  ) -> T): Query<T> = SearchQuery(query) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!,
      cursor.getDouble(3)!!,
      cursor.getDouble(4)!!,
      cursor.getString(5)
    )
  }

  public fun search(query: String): Query<Stop> = search(query) { id, code, name, lat, lon,
      parent ->
    Stop(
      id,
      code,
      name,
      lat,
      lon,
      parent
    )
  }

  public fun <T : Any> getById(id: String, mapper: (
    id: String,
    code: String,
    name: String,
  ) -> T): Query<T> = GetByIdQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      cursor.getString(2)!!
    )
  }

  public fun getById(id: String): Query<GetById> = getById(id) { id_, code, name ->
    GetById(
      id_,
      code,
      name
    )
  }

  private inner class Search2Query<out T : Any>(
    public val query: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("Stop", "StopSearch", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("Stop", "StopSearch", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(129_466_004, """
    |SELECT Stop.*
    |FROM StopSearch
    |JOIN Stop ON StopSearch.id = Stop.id
    |WHERE StopSearch MATCH ? || '*'
    """.trimMargin(), mapper, 1) {
      bindString(0, query)
    }

    override fun toString(): String = "StopSearch.sq:search2"
  }

  private inner class SearchQuery<out T : Any>(
    public val query: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("Stop", "StopSearch", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("Stop", "StopSearch", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(974_007_646, """
    |SELECT *
    |FROM Stop
    |WHERE id IN (
    |    SELECT id
    |    FROM StopSearch
    |    WHERE StopSearch MATCH ? || '*'
    |)
    """.trimMargin(), mapper, 1) {
      bindString(0, query)
    }

    override fun toString(): String = "StopSearch.sq:search"
  }

  private inner class GetByIdQuery<out T : Any>(
    public val id: String,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("StopSearch", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("StopSearch", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> =
        driver.executeQuery(-1_914_506_414, """SELECT * FROM StopSearch WHERE id = ?""", mapper, 1)
        {
      bindString(0, id)
    }

    override fun toString(): String = "StopSearch.sq:getById"
  }
}
