package ca.derekellis.reroute.db

import app.cash.sqldelight.Query
import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlCursor
import app.cash.sqldelight.db.SqlDriver
import io.github.dellisd.spatialk.geojson.LineString
import kotlin.Any
import kotlin.Int
import kotlin.String
import kotlin.collections.Collection

public class RouteVariantQueries(
  driver: SqlDriver,
  private val RouteVariantAdapter: RouteVariant.Adapter,
) : SuspendingTransacterImpl(driver) {
  public fun <T : Any> getAll(mapper: (
    id: String,
    gtfsId: String,
    directionId: Int,
    headsign: String,
    weight: Int,
    shape: LineString,
  ) -> T): Query<T> = Query(-1_736_002_093, arrayOf("RouteVariant"), driver, "RouteVariant.sq",
      "getAll", "SELECT * FROM RouteVariant") { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      RouteVariantAdapter.directionIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getString(3)!!,
      RouteVariantAdapter.weightAdapter.decode(cursor.getLong(4)!!),
      RouteVariantAdapter.shapeAdapter.decode(cursor.getString(5)!!)
    )
  }

  public fun getAll(): Query<RouteVariant> = getAll { id, gtfsId, directionId, headsign, weight,
      shape ->
    RouteVariant(
      id,
      gtfsId,
      directionId,
      headsign,
      weight,
      shape
    )
  }

  public fun <T : Any> getByIds(id: Collection<String>, mapper: (
    id: String,
    gtfsId: String,
    directionId: Int,
    headsign: String,
    weight: Int,
    shape: LineString,
  ) -> T): Query<T> = GetByIdsQuery(id) { cursor ->
    mapper(
      cursor.getString(0)!!,
      cursor.getString(1)!!,
      RouteVariantAdapter.directionIdAdapter.decode(cursor.getLong(2)!!),
      cursor.getString(3)!!,
      RouteVariantAdapter.weightAdapter.decode(cursor.getLong(4)!!),
      RouteVariantAdapter.shapeAdapter.decode(cursor.getString(5)!!)
    )
  }

  public fun getByIds(id: Collection<String>): Query<RouteVariant> = getByIds(id) { id_, gtfsId,
      directionId, headsign, weight, shape ->
    RouteVariant(
      id_,
      gtfsId,
      directionId,
      headsign,
      weight,
      shape
    )
  }

  private inner class GetByIdsQuery<out T : Any>(
    public val id: Collection<String>,
    mapper: (SqlCursor) -> T,
  ) : Query<T>(mapper) {
    override fun addListener(listener: Query.Listener) {
      driver.addListener("RouteVariant", listener = listener)
    }

    override fun removeListener(listener: Query.Listener) {
      driver.removeListener("RouteVariant", listener = listener)
    }

    override fun <R> execute(mapper: (SqlCursor) -> QueryResult<R>): QueryResult<R> {
      val idIndexes = createArguments(count = id.size)
      return driver.executeQuery(null, """SELECT * FROM RouteVariant WHERE id IN $idIndexes""",
          mapper, id.size) {
            id.forEachIndexed { index, id_ ->
              bindString(index, id_)
            }
          }
    }

    override fun toString(): String = "RouteVariant.sq:getByIds"
  }
}
