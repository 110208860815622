package ca.derekellis.reroute.db

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.SqlDriver

public class RouteQueries(
  driver: SqlDriver,
  private val RouteAdapter: Route.Adapter,
) : SuspendingTransacterImpl(driver) {
  public suspend fun insert(Route: Route) {
    driver.execute(1_439_636_338,
        """INSERT OR IGNORE INTO Route (gtfsId, identifier, destinations) VALUES (?, ?, ?)""", 3) {
          bindString(0, Route.gtfsId)
          bindString(1, Route.identifier)
          bindString(2, RouteAdapter.destinationsAdapter.encode(Route.destinations))
        }.await()
    notifyQueries(1_439_636_338) { emit ->
      emit("Route")
    }
  }
}
