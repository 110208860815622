package ca.derekellis.reroute.db

import app.cash.sqldelight.ColumnAdapter
import kotlin.Int
import kotlin.Long
import kotlin.String

public data class RouteVariantAtStop(
  public val stopId: String,
  public val routeVariantId: String,
  public val index: Int,
) {
  public class Adapter(
    public val indexAdapter: ColumnAdapter<Int, Long>,
  )
}
