package ca.derekellis.reroute.db

import app.cash.sqldelight.ColumnAdapter
import kotlin.String
import kotlin.collections.List

public data class Route(
  public val gtfsId: String,
  public val identifier: String,
  public val destinations: List<String>,
) {
  public class Adapter(
    public val destinationsAdapter: ColumnAdapter<List<String>, String>,
  )
}
