package ca.derekellis.reroute.db

import kotlin.Int
import kotlin.String
import kotlin.collections.List

public data class GetRoutesByStopCode(
  public val gtfsId: String,
  public val identifier: String,
  public val destinations: List<String>,
  public val directionId: Int,
)
