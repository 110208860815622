package ca.derekellis.reroute.db.web

import app.cash.sqldelight.SuspendingTransacterImpl
import app.cash.sqldelight.db.AfterVersion
import app.cash.sqldelight.db.QueryResult
import app.cash.sqldelight.db.SqlDriver
import app.cash.sqldelight.db.SqlSchema
import ca.derekellis.reroute.db.Metadata
import ca.derekellis.reroute.db.MetadataQueries
import ca.derekellis.reroute.db.RerouteDatabase
import ca.derekellis.reroute.db.Route
import ca.derekellis.reroute.db.RouteQueries
import ca.derekellis.reroute.db.RouteVariant
import ca.derekellis.reroute.db.RouteVariantAtStop
import ca.derekellis.reroute.db.RouteVariantAtStopQueries
import ca.derekellis.reroute.db.RouteVariantQueries
import ca.derekellis.reroute.db.StopQueries
import ca.derekellis.reroute.db.StopSearchQueries
import kotlin.Long
import kotlin.Unit
import kotlin.reflect.KClass

internal val KClass<RerouteDatabase>.schema: SqlSchema<QueryResult.AsyncValue<Unit>>
  get() = RerouteDatabaseImpl.Schema

internal fun KClass<RerouteDatabase>.newInstance(
  driver: SqlDriver,
  MetadataAdapter: Metadata.Adapter,
  RouteAdapter: Route.Adapter,
  RouteVariantAdapter: RouteVariant.Adapter,
  RouteVariantAtStopAdapter: RouteVariantAtStop.Adapter,
): RerouteDatabase = RerouteDatabaseImpl(driver, MetadataAdapter, RouteAdapter, RouteVariantAdapter,
    RouteVariantAtStopAdapter)

private class RerouteDatabaseImpl(
  driver: SqlDriver,
  MetadataAdapter: Metadata.Adapter,
  RouteAdapter: Route.Adapter,
  RouteVariantAdapter: RouteVariant.Adapter,
  RouteVariantAtStopAdapter: RouteVariantAtStop.Adapter,
) : SuspendingTransacterImpl(driver), RerouteDatabase {
  override val metadataQueries: MetadataQueries = MetadataQueries(driver, MetadataAdapter)

  override val routeQueries: RouteQueries = RouteQueries(driver, RouteAdapter)

  override val routeVariantQueries: RouteVariantQueries = RouteVariantQueries(driver,
      RouteVariantAdapter)

  override val routeVariantAtStopQueries: RouteVariantAtStopQueries =
      RouteVariantAtStopQueries(driver, RouteVariantAtStopAdapter)

  override val stopQueries: StopQueries = StopQueries(driver, RouteAdapter, RouteVariantAdapter)

  override val stopSearchQueries: StopSearchQueries = StopSearchQueries(driver)

  public object Schema : SqlSchema<QueryResult.AsyncValue<Unit>> {
    override val version: Long
      get() = 1

    override fun create(driver: SqlDriver): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
      driver.execute(null, """
          |CREATE TABLE Metadata (
          |    updated TEXT NOT NULL
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE Route (
          |  gtfsId TEXT PRIMARY KEY,
          |  identifier TEXT NOT NULL,
          |  destinations TEXT NOT NULL
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE RouteVariant (
          |  id TEXT PRIMARY KEY,
          |  gtfsId TEXT NOT NULL,
          |  directionId INTEGER NOT NULL,
          |  headsign TEXT NOT NULL,
          |  weight INTEGER NOT NULL,
          |  shape TEXT NOT NULL,
          |  FOREIGN KEY (gtfsId) REFERENCES Route(gtfsId)
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE RouteVariantAtStop (
          |  stopId TEXT NOT NULL,
          |  routeVariantId TEXT NOT NULL,
          |  `index` INTEGER NOT NULL,
          |  FOREIGN KEY (stopId) REFERENCES Stop(id),
          |  FOREIGN KEY (routeVariantId) REFERENCES RouteVariant(id)
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TABLE Stop (
          |    id TEXT NOT NULL PRIMARY KEY,
          |    code TEXT NOT NULL,
          |    name TEXT NOT NULL,
          |    lat REAL NOT NULL,
          |    lon REAL NOT NULL,
          |    parent TEXT
          |)
          """.trimMargin(), 0).await()
      driver.execute(null, "CREATE INDEX idx_stop_code ON Stop(code)", 0).await()
      driver.execute(null, """
          |CREATE TRIGGER update_stop_search AFTER INSERT ON Stop
          |BEGIN
          |INSERT INTO StopSearch(id, code, name)
          |VALUES(new.id, new.code, new.name);
          |END
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE TRIGGER update_stop_search_delete BEFORE DELETE ON Stop
          |BEGIN
          |DELETE FROM StopSearch WHERE id = old.id;
          |END
          """.trimMargin(), 0).await()
      driver.execute(null, """
          |CREATE VIRTUAL TABLE StopSearch USING fts3 (
          |    id TEXT NOT NULL,
          |    code TEXT NOT NULL,
          |    name TEXT NOT NULL,
          |    tokenize=unicode61
          |)
          """.trimMargin(), 0).await()
    }

    override fun migrate(
      driver: SqlDriver,
      oldVersion: Long,
      newVersion: Long,
      vararg callbacks: AfterVersion,
    ): QueryResult.AsyncValue<Unit> = QueryResult.AsyncValue {
    }
  }
}
